const palette = {
  colors: [
    '#8be9fd',
    '#50fa7b',
    '#ffb86c',
    '#ff79c6',
    '#bd93f9',
    '#ff5555',
    '#f1fa8c',
  ]
};

export default palette;